import React, { Dispatch } from 'react';

import { EnquiryData } from 'types/Enquiry';
import { Product } from 'types/Product';

export interface EnquiryContextProps {
  addProduct: (s: number[]) => void;
  removeProduct: (id: number) => void;
  open: boolean;
  loading: boolean;
  sent: boolean;
  send: (data: EnquiryData) => Promise<void>;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  token: string;
  products: Product[];
  stored: number[];
  countries: string[];
}

const EnquiryContext = React.createContext<EnquiryContextProps>({} as EnquiryContextProps);

export default EnquiryContext;

export const useEnquiries = () => React.useContext(EnquiryContext);
